function cssProp(cssValue: any) {
    const stringValue = String(cssValue || '');
    const [_, value, units]: any = stringValue.match(/(\d+)\s*(\w*)/i) || [];

    if (!value) return '';

    return `${value}${units || 'px'}` || undefined;
}

function getHeightByRows(rows: number, el: any){
    if (!el) return {};
    const style = window.getComputedStyle(el.value);
    const pixels = rows * parseFloat(style.lineHeight) + 'px';
    
    return pixels;
}

export { 
    cssProp,
    getHeightByRows
}