
export function useValidation(valueRef: any, rulesColl: any[]) {

  const errors = ref([] as any[]);
  const hasErrors = computed(() => errors.value.length > 0)

  let rulesToValidate: any = rulesColl;
  let valueToValidate: any = valueRef;

  function validate() {
    if (!rulesToValidate) return [];
    errors.value = validateRules(rulesToValidate, valueToValidate?.value)
    return errors.value;
  }

  function reset() {
    valueToValidate.value = '';
  }

  function resetValidation() {
    errors.value = [];
  }

  function validateRules(rules: any[], value: any) {
    const rulesValue = value != undefined ? value : value;
    const errors = rules?.map((rule) => rule(rulesValue)).filter((el) => el != true);

    return errors;
  }

  return {
    validate,
    reset,
    resetValidation,
    errors,
    hasErrors,
  }
}
